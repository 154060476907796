@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: 62 175 140; /* 3EAF8C */
  --color-primary-light: 185 240 221;
  --color-primary-dark: 0 127 95;
  --color-background: 255 255 255;
  --color-background-hover: rgb(247, 247, 247);
  --color-dark: #292929;
  --color-foreground: 0 0 0;
  --color-foreground-hover: rgba(0, 0, 0, 0.64);
  --color-secondary: 177 216 198;
  --color-dark-gray: #696969;
  --light-gray: #949494;
  --color-border: rgba(150, 150, 150, 0.15);
  --color-border-light: rgba(150, 150, 150, 0.4);
  --header-height: 64px;
  --header-height-mobile: 56px;
}

/* Inspiration from https://www.contentlayer.dev/docs */
.dark {
  --color-background: 10 11 14; /* 16 21 25 18 18 18 rgba(14, 14, 16); #0e0e10;  #121212  blue: rgba(16,21,25,1.0) */
  --color-background-hover: rgba(
    247,
    247,
    247,
    0.07
  ); /* <--bg-gray-900 rgba(247, 247, 247, 0.1); */
  --color-dark: #cccccc;
  --color-foreground: 200 205 211;
  --color-foreground-hover: #abafb5;
  --color-border: rgb(25, 31, 37);
  --color-border-light: rgba(61, 61, 61, 0.7);
  --color-dark-gray: rgba(255, 255, 255, 0.6);
  --light-gray: rgba(255, 255, 255, 0.34);
  --skeleton: 255, 255, 255;
}

@layer base {
  ::selection {
    @apply bg-primary text-[#fefefe];
  }

  a {
    @apply cursor-pointer text-primary;
  }
}

.prose blockquote {
  display: block;
  font-size: 130%;
  font-family: 'Taviraj Georgia serif';
  font-style: normal;
  color: var(--color-foreground) !important;
  padding-left: 30px;
  border-left: 2px solid rgb(var(--color-primary));
  margin-left: 6%;
  width: 94%;
}

/* TODO: Remove when updated to Tailwind v3 */
@layer utilities {
  .scroll-snap-none {
    scroll-snap-type: none;
  }
  .scroll-snap-x {
    scroll-snap-type: x mandatory;
  }
  .scroll-snap-y {
    scroll-snap-type: y;
  }

  .scroll-start-align {
    scroll-snap-align: start;
  }
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  .no-scrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
}

/* Remove Safari input shadow on mobile */
input[type='text'],
input[type='email'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

 {
  /* Skeleton Overrides */
}
.dark .MuiSkeleton-root {
  --color-skeleton: 255, 255, 255;
}
.MuiSkeleton-root {
  --color-skeleton: 0, 0, 0;
  background-color: rgba(var(--color-skeleton), 0.05) !important;
}

.overlay-bg:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.35) 50%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.overlay-bg-left:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    -90deg,
    transparent,
    rgba(0, 0, 0, 0.1) 15%,
    rgba(0, 0, 0, 0.5) 60%,
    #090909
  );
}

.overlay-bg-ipo:after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
  z-index: 2;
  background: rgba(6, 47, 45, 0.82);
}

@keyframes ping-slow {
  90%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@layer components {
  .badge {
    @apply bg-primary-100 text-primary-700 dark:text-white dark:bg-primary;
  }
}
